import {getUser} from "../cjauth";
import {PageHeader, PageTitle} from "@cjdev-internal/visual-stack/lib/components/PageHeader";
import PageContent from "@cjdev-internal/visual-stack/lib/components/PageContent";
import {Body, Header, Panel} from "@cjdev-internal/visual-stack/lib/components/Panel";
import React, {useEffect, useState} from "react";
import {redirectToLogin} from "./LoginRedirect";
import {isAuthenticated} from "../cjauth/impl/oauth";
import {authConfig} from "../utils/authConfig";

export const PageView = ({route, ...props}) => {
    const {name, header, Component, requiresAuth} = route;
    const user = getUser();

    const [isAuthorized, setAuthorized] = useState(false)
    const [authCheckComplete, setAuthCheckComplete] = useState(false)

    useEffect(() => {
        const verifyAuthorization = async () => {
            if (requiresAuth) {
                const authenticated = await isAuthenticated()
                const userExists = !!user
                const userIsEmployee = user?.isEmployee
                const authorized = (authenticated === true) && userExists && userIsEmployee
                setAuthorized(authorized)
            }
            setAuthCheckComplete(true)
        }
        verifyAuthorization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (requiresAuth && !authCheckComplete) {
        return null
    } else if (requiresAuth && authCheckComplete && !isAuthorized) {
        redirectToLogin()
        return null
    } else {
        return (
            <div>
                <PageHeader>
                    <PageTitle>{name}</PageTitle>
                </PageHeader>
                <PageContent>
                    <Panel>
                        {header !== "" && <Header><h3>{header}</h3></Header>}
                        <Body>
                            <Component {...props} user={user}/>
                        </Body>
                    </Panel>
                </PageContent>
            </div>
        );
    }
};

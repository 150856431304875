import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { OauthCallback } from "./cjauth";
import {routes} from "./routes";
import {Login} from "./components/Login";
import '@cjdev-internal/visual-stack';
import './App.css';
import {authConfig} from "./utils/authConfig";
import {PageView} from "./components/PageView";
import {AppLayoutView} from "./components/AppLayoutView";

export const ADMINS = ['frum', 'nitin.keswani', 'miguel-angel.rodriguez', 'nestor.arias', 'abraham.alamilla'];

const App = ({features}) => {
  const childComponents = Object.values(routes(features)).map(
      route => (
          <Route key={route.path} exact path={route.path}>
            <PageView route={route} features={features}/>
          </Route>
      )
  )

  const appLayout = <AppLayoutView features={features}>
    {childComponents}
  </AppLayoutView>

  const authConfig0 = authConfig();

  return <Router>
    <Switch>
      <Route path="/login">
        <Login/>
      </Route>
      <Route path="/oauth-callback">
        <OauthCallback authConfig={authConfig0} />
      </Route>
      <Route path={"/"}>
        {appLayout}
      </Route>
    </Switch>
  </Router>
}

export default App;


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {getFeatures} from "./api/features-api"
import { authConfig } from "./utils/authConfig";
import { initAuth } from '@platform/platform-auth'

const myAuthConfig = authConfig()

initAuth({
    clientId: myAuthConfig.clientId,
    redirectUri: myAuthConfig.redirectUri
}).then(() => {
  return getFeatures();
}).then(features => {
    ReactDOM.render(
        <React.StrictMode>
            <App features={features}/>
        </React.StrictMode>,
        document.getElementById('root')
    );
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useEffect} from 'react';
import { getBearerToken } from '@platform/platform-auth'
import * as Storage from "../impl/storage";
import {getEmailAddress} from "../impl/oauth";

export const CJLogin = ({authConfig}) => {

    useEffect(() => {
        doLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authConfig]);

    const doLogin = async () => {
        const token = await getBearerToken()
        
        if ( token ) {
            Storage.set(Storage.AUTHENTICATION_TOKEN, token)
            await getEmailAddress(authConfig, token)
            window.location.assign("/");
        }
    }

    return <div/>;
};
import {useEffect, useState} from "react";
import {isAuthenticated} from "./oauth";

export const useAuthentication = (authConfig) => {
    const [hasCheckedAuthentication, setHasCheckedAuthentication] = useState(false)
    const [authenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        (async () => {
            const isAuthenticatedFromAuth = await isAuthenticated();
            setIsAuthenticated(isAuthenticatedFromAuth);
            setHasCheckedAuthentication(true);
        })();
    }, [authConfig]);

    return {hasCheckedAuthentication, authenticated}
}

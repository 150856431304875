
export const authConfig = () => {
    const localConfig = {
        "clientId": "k6i4ZGL7gQJ6vjMEuq2QqFcZoC33lACm",
        "memberUserUri": "https://members.cj.com/affapi/oauth/user",
        "redirectUri": "https://local.cj.com:3000/oauth-callback"
    }

    const labConfig = {
        "clientId": "k6i4ZGL7gQJ6vjMEuq2QqFcZoC33lACm",
        "memberUserUri": "https://members.cj.com/affapi/oauth/user",
        "redirectUri": "https://operations-console.lab.cj.com/oauth-callback"
    }

    const prodConfig = {
        "clientId": "k6i4ZGL7gQJ6vjMEuq2QqFcZoC33lACm",
        "memberUserUri": "https://members.cj.com/affapi/oauth/user",
        "redirectUri": "https://operations.prod.cj.com/oauth-callback"
    }

    const hostname = window.location.hostname;

    if (hostname.includes("local")) {
        return localConfig;
    }

    if (hostname.includes("cjpowered.com") || hostname.includes("lab.cj.com")) {
        return labConfig;
    }

    if (hostname.includes("cj.dev") || hostname.includes("prod.cj.com")) {
        return prodConfig;
    }

    throw Error("No oauth configuration found for this host: " + hostname);
}

import * as Storage from "./storage";
import { getUserId, isLoggedIn, logout as logoutPlatform } from "@platform/platform-auth";

export const getEmailAddress = async (authConfig, accessToken) => {
    const memberUser = await fetchMemberUser(authConfig, accessToken)
    delete memberUser.companies;
    memberUser.emailAddress = memberUser.emailAddress.toLowerCase().trim()
    Storage.set(Storage.MEMBER_USER, btoa(JSON.stringify(memberUser)));
}

const logout = () => {
    Storage.remove(Storage.AUTHENTICATION_TOKEN);
    Storage.remove(Storage.MEMBER_USER);
    logoutPlatform();
}

const isAuthenticated = async () => {
    return await isLoggedIn();
};

const getUser = () => {
    let memberUserValue = Storage.get(Storage.MEMBER_USER);
    if(memberUserValue !== null){
        return JSON.parse(atob(memberUserValue))
    }
}

const getAuthToken = () => Storage.get(Storage.AUTHENTICATION_TOKEN);

const fetchWithAuthBearerToken = async (url, token) => {
    return fetch(url, {
        headers: {
            authorization: `Bearer ${token}`
        }
    });
}

const fetchMemberUser = async (authConfig, token) => {
    const userId = await getUserId();
    const response = await fetchWithAuthBearerToken(`${authConfig.memberUserUri}/${userId}`, token);

    return await response.json();
}

export { isAuthenticated, getUser, getAuthToken, logout }
import React, { useEffect } from 'react';

import { handleOAuthCallback } from "@platform/platform-auth";

export const OauthCallback = () => {
    useEffect(() => {
        doOauthCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doOauthCallback = async () => {
        handleOAuthCallback()
    }

    return <div/>;
};